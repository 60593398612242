import styled from 'styled-components'
import HomeMainBanner from '@/components/organisms/home/homeMainBanner'
import ProductsSlider, {
  ProductSlide
} from '@/components/organisms/productsSlider'
import { HomeBanner } from '@/network/graphql.g'
import { FC, useEffect, useState } from 'react'
import useDevice from 'hooks/useDevice'

const MainBannerSlider: FC<{
  bannerElements: HomeBanner[]
  isHomePageBanner?: boolean
}> = ({ bannerElements, isHomePageBanner }) => {
  const { isDesktop, isMobile, isTablet } = useDevice()
  const [banners, setBanners] = useState<HomeBanner[]>(
    bannerElements.slice(0, 1)
  )
  useEffect(() => {
    setBanners(bannerElements)
  }, [])

  return (
    <StyledProductsSlider
      pauseOnTouchMove={isMobile || isTablet}
      slidesToShow={1}
      autoPlay={{
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: isDesktop
      }}
      showPagination={true}
      infinite={true}
      fillArrowBackground={false}
      isHomePageBanner={isHomePageBanner}
    >
      {banners.map((banner, offset) => (
        <ProductSlide key={banner.imageBig}>
          <HomeMainBanner
            imgSrc={banner.imageBig}
            imgSrcMobile={banner.imageMobile}
            primaryUrl={banner.url1}
            secondaryUrl={banner.url2}
            priority={offset === 0}
            id={banner.id}
            position={banner.position}
            title={banner.title}
            sortOrder={banner.sortOrder}
          />
        </ProductSlide>
      ))}
    </StyledProductsSlider>
  )
}

export default MainBannerSlider

const StyledProductsSlider = styled(ProductsSlider)`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius}px;
`
