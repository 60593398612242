import { Product } from '@/network/graphql.g'
import { StoreContext } from '@/providers/storeProvider'
import { useContext, useEffect } from 'react'
import { SlugPathItem } from 'types/slugPath'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { BridgeProduct } from '@/types/bridgeProduct'

type SeoBreadcrumbListProps = {
  slugPathValues: SlugPathItem[]
  baseUrl: string
}

type ListItem = {
  '@type': string
  position: number
  item: {
    '@id': string
    name: string
  }
}

type SeoProductListProps = {
  productList: Product[]
  baseUrl: string
}

type SeoProduct = {
  '@context': string
  '@type': string
  name: string
  image: string[]
  description?: string
  sku: string
  mpn: string
  brand: {
    '@type': string
    name: string
  }
  offers: {
    '@type': string
    url: string
    availability?: number
    priceCurrency: string
    price: string
    itemCondition: string
    seller: {
      '@type': string
      name: string
    }
  }
}

type SeoProductProps = {
  product: Product
  baseUrl: string
}

type SeoHomePage = {
  '@context': 'http://schema.org'
  '@type': 'WebSite'
  name: string
  url: string
  potentialAction: {
    '@type': 'SearchAction'
    target: {
      '@type': 'EntryPoint'
      urlTemplate: string
    }
    'query-input': 'required name=search_term_string'
  }
}

type SeoTechnicalPage = {
  '@context': 'http://schema.org'
  '@type': string
  name: string
  description: string
  url: string
}

type SeoTechnicalPageProps = {
  typePage: 'About' | 'Contact'
}

type AddJSToHtmlProps = {
  contentHTML: string
  id: string
}

const imgUrlProductList = 'https://i.factcool.com/cache2/410x615/'
const imgUrlProduct = 'https://i.factcool.com/cache2/800x1200/'

export function SeoBreadcrumbList({
  slugPathValues,
  baseUrl
}: SeoBreadcrumbListProps): JSX.Element | null {
  const homeItem: ListItem = {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@id': baseUrl,
      name: 'Home'
    }
  }

  const transformData = (data: SlugPathItem[]): ListItem[] => {
    const transformedData = data.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${baseUrl}${item.url}`,
        name: item.title
      }
    }))

    return [homeItem, ...transformedData]
  }

  const itemListElement = transformData(slugPathValues)

  const contentHTML = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElement
  })

  return <AddJSToHtml contentHTML={contentHTML} id={'breadcrumb'} />
}

export function SeoProductList({
  productList,
  baseUrl
}: SeoProductListProps): JSX.Element | null {
  if (!productList) {
    return
  }

  const contentHTML = JSON.stringify(
    productList.map((element) =>
      getProductItem(element, baseUrl, imgUrlProductList)
    )
  )

  return <AddJSToHtml contentHTML={contentHTML} id={'product-list'} />
}

export function SeoProduct({
  product,
  baseUrl
}: SeoProductProps): JSX.Element | null {
  if (!product) {
    return
  }

  const contentHTML = JSON.stringify(
    getProductItem(product, baseUrl, imgUrlProduct)
  )

  return <AddJSToHtml contentHTML={contentHTML} id={'product-detail'} />
}

export function SeoHomePage(): JSX.Element | null {
  const { domainName } = useContext(StoreContext)
  const url = getURL()

  if (!url) {
    return null
  }

  const contentHTML = JSON.stringify(getHomePageData(domainName, url))

  return <AddJSToHtml contentHTML={contentHTML} id={'home-page'} />
}

export function SeoTechnicalPage({
  typePage
}: SeoTechnicalPageProps): JSX.Element | null {
  const { t } = useTranslation('common')
  const url = getURL()

  if (!url) {
    return null
  }

  const name = t(`Seo.${typePage}.name`)
  const description = t(`Seo.${typePage}.description`)

  const contentHTML = JSON.stringify(
    getTechnicalPageData(typePage, name, description, url)
  )

  return <AddJSToHtml contentHTML={contentHTML} id={typePage} />
}

function getProductItem(
  product: BridgeProduct,
  baseUrl: string,
  urlImg: string
): SeoProduct {
  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: product?.name,
    image: product?.allImages.map((image) => `${urlImg}${image.url}`),
    description: product?.description?.description,
    sku: product?.id,
    mpn: product?.id,
    brand: {
      '@type': 'Thing',
      name: product?.productBrand
    },
    offers: {
      '@type': 'Offer',
      url: baseUrl + '/product/' + product?.id,
      priceCurrency: product?.currency?.localCurrencyCode
        ? product?.currency?.localCurrencyCode
        : 'EUR',
      price: product?.prices?.finalPrice?.toFixed(
        product?.currency?.decimalPlaces
      ),
      itemCondition: 'http://schema.org/NewCondition',
      seller: {
        '@type': 'Organization',
        name: 'Factcool'
      }
    }
  }
}

function getHomePageData(domainName: string, url: string): SeoHomePage {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: domainName,
    url: url,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: url + '/search?q={search_term_string}'
      },
      'query-input': 'required name=search_term_string'
    }
  }
}

function getTechnicalPageData(
  typePage: string,
  name: string,
  description: string,
  url: string
): SeoTechnicalPage {
  return {
    '@context': 'http://schema.org',
    '@type': typePage,
    name: name,
    description: description,
    url: url
  }
}

function getURL(): string | null {
  if (typeof window !== 'undefined') {
    return window.location.href
  } else {
    return null
  }
}

function AddJSToHtml({
  contentHTML,
  id
}: AddJSToHtmlProps): JSX.Element | null {
  const router = useRouter()

  useEffect(() => {
    const scriptId = `json-ld-${id}`
    const script = document.createElement('script')
    script.id = scriptId
    script.type = 'application/ld+json'
    script.innerHTML = contentHTML
    document.body.appendChild(script)

    return () => {
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        existingScript.remove()
      }
    }
  }, [contentHTML, id, router.asPath])

  return null
}
